import {
  GET_ALL_INVENTORY,
  GET_INVENTORY_BY_FILTER,
  GET_INVENTORY_BY_ID,
  UPDATE_INVENTORY_BY_ID,
} from "@/api/queries/inventoryQ";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { apolloClient, nhost } from "@/main";
import { provideApolloClient } from "@vue/apollo-composable";

interface InventoryAction {
  status: string;
}

export async function getAllInventory() {
  const { result, loading, error } = await provideApolloClient(apolloClient)(
    () =>
      useQuery(
        GET_ALL_INVENTORY,
        {},
        {
          fetchPolicy: "no-cache",
        }
      )
  );

  return {
    result,
    loading,
    error,
  };
}

export const getInventoryByFilter = async (filters) => {
  try {
    const whereObject = {
      _and: [] as any,
    };

    if (filters?.volume_of_holders?.label) {
      whereObject._and.push({
        volume_of_holders: { _eq: filters.volume_of_holders.label },
      });
    }

    if (filters?.profile_holders?.value?.id) {
      whereObject._and.push({
        profile_holders: {
          id_combo_data_profile_holders: {
            _eq: filters.profile_holders.value.id,
          },
        },
      });
    }

    if (filters?.location_holders?.value?.id) {
      whereObject._and.push({
        location_holders: {
          id_combo_data_location_holders: {
            _eq: filters.location_holders.value.id,
          },
        },
      });
    }

    if (filters?.data_types?.value?.id) {
      whereObject._and.push({
        data_types: {
          id_combo_data_data_types: { _eq: filters.data_types.value.id },
        },
      });
    }
    const initialLabel = filters?.initial?.label;

    if (initialLabel === "Alto") {
      whereObject._and.push({
        risk_treatment_activities: {
          risk: {
            initial_risk: { _eq: "Alto" },
          },
        },
      });
    }

    if (initialLabel === "Médio") {
      whereObject._and.push({
        _not: {
          risk_treatment_activities: {
            risk: {
              initial_risk: { _eq: "Alto" },
            },
          },
        },

        risk_treatment_activities: {
          risk: {
            initial_risk: { _eq: "Médio" },
          },
        },
      });
    }

    if (initialLabel === "Baixo") {
      whereObject._and.push({
        _not: {
          _and: [
            {
              risk_treatment_activities: {
                risk: {
                  initial_risk: { _eq: "Alto" },
                },
              },
            },
            {
              risk_treatment_activities: {
                risk: {
                  initial_risk: { _eq: "Médio" },
                },
              },
            },
          ],
        },
        // Garante que existe pelo menos um risco "Baixo"
        risk_treatment_activities: {
          risk: {
            initial_risk: { _eq: "Baixo" },
          },
        },
      });
    }

    if (filters?.residual?.label) {
      whereObject._and.push({
        risk_treatment_activities: {
          risk: {
            risk_monitoring: {
              residual_risk: {
                _eq: filters.residual.label,
              },
            },
          },
        },
      });
    }
    // Salvar o whereObject no localStorage por usuário
    const userId = nhost.auth.getUser().id;
    const filtersKey = `whereObject_${userId}`;
    localStorage.setItem(filtersKey, JSON.stringify(whereObject));

    const variables = {
      where: whereObject,
    };

    const { data } = await apolloClient.query({
      query: GET_INVENTORY_BY_FILTER,
      variables,
      fetchPolicy: "no-cache",
    });

    return data.data_mapping_treatment_activity;
  } catch (error) {
    console.error("Erro ao buscar os dados filtrados", error);
  }
};

export async function updateInventoryById(
  id: number,
  inventory: InventoryAction
) {
  try {
    const { mutate: mutateUpdate } = provideApolloClient(apolloClient)(() =>
      useMutation(UPDATE_INVENTORY_BY_ID)
    );
    const response = await mutateUpdate({
      id: id,
      obj_inventory: inventory,
    });

    const updatedData =
      response?.data?.update_data_mapping_treatment_activity_by_pk;
    return updatedData;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getInventoryById(id: number) {
  const { result, loading } = await provideApolloClient(apolloClient)(() =>
    useQuery(
      GET_INVENTORY_BY_ID,
      { id },
      {
        fetchPolicy: "no-cache",
      }
    )
  );

  return { result, loading };
}
