import { gql } from "graphql-tag";

export const GET_ALL_INVENTORY = gql`
  query getAllInventory {
    data_mapping_treatment_activity {
      created_at
      to_be_reminded_at
      description
      id
      name_activity
      status
      user_responsible {
        displayName
        id
      }
      owner {
        id
        displayName
      }
      area_modules {
        area {
          description
        }
        id_module
      }
      risk_treatment_activities {
        id_treatment_activity
        risk {
          initial_risk
          risk_monitoring(limit: 1, order_by: { created_at: desc }) {
            residual_risk
          }
        }
      }
      assessments {
        supplier_controller {
          supplier {
            fantasy_name
            corporate_reason
          }
        }
        supplier_controller_set {
          supplier {
            corporate_reason
            fantasy_name
          }
        }
        supplier_operator {
          supplier {
            fantasy_name
            corporate_reason
          }
        }
        combo_data_legal_base {
          value
          id
        }
      }
      category_holders {
        combo_data_category_holders {
          value
          id
        }
      }
      profile_holders {
        combo_data {
          value
          id
        }
      }
      volume_of_holders
    }
    data_mapping_treatment_activity_temporary {
      id
      user {
        id
        displayName
        email
      }
      created_at
      values
    }
  }
`;
export const GET_INVENTORY_BY_ID = gql`
  subscription getInventoryById($id: Int!) {
    data_mapping_treatment_activity_by_pk(id: $id) {
      created_at
      description
      id
      name_activity
      status
      id_user_dpo
      id_user_owner
      id_user_responsible
      owner {
        id
        displayName
      }
      area_modules {
        id
        area {
          description
          id
        }
      }
      user_dpo {
        displayName
        id
      }
      user_responsible {
        displayName
        id
      }
      risk_treatment_activities {
        id_treatment_activity
        risk {
          initial_risk
          risk_monitoring(limit: 1, order_by: { created_at: desc }) {
            residual_risk
          }
        }
      }
      log_activity_treatments {
        id
        date_time
        log_description
      }
      description
      finality
      user_responsible {
        displayName
        id
      }
      user_dpo {
        id
        displayName
      }
      storage_period
      is_discard_elimination
      disposal_description
      retention_description
      consents {
        combo_data {
          value
          id
        }
      }
      mens_consents {
        combo_data {
          value
          id
        }
      }
      opt_in
      automatic_decision_description
      category_holders {
        combo_data_category_holders {
          value
          id
        }
      }
      profile_holders {
        combo_data {
          value
          id
        }
      }
      location_holders {
        combo_data {
          value
          id
        }
      }
      volume_of_holders
      data_types {
        combo_data {
          value
          id
        }
      }
      security_measures {
        combo_data {
          value
          id
        }
      }
      data_countries {
        combo_data {
          value
          id
        }
      }
      is_data_in_cloud
      is_shared_data_companies
      is_automatic_decision
      is_shared_data_suppliers
      area_modules {
        area {
          description
        }
      }
      sharing_data_areas {
        combo_data {
          value
          id
        }
      }
      related_suppliers {
        suppliers_module {
          supplier {
            fantasy_name
            id
          }
        }
      }
      supplier_locations {
        combo_data {
          value
          id
        }
      }
      sharing_data_suppliers {
        combo_data {
          value
          id
        }
      }
      additional_description
    }
  }
`;

export const UPDATE_INVENTORY_BY_ID = gql`
  mutation UpdateInventory(
    $id: Int!
    $obj_inventory: data_mapping_treatment_activity_set_input!
  ) {
    update_data_mapping_treatment_activity_by_pk(
      pk_columns: { id: $id }
      _set: $obj_inventory
    ) {
      id
      status
    }
  }
`;

export const GET_INVENTORY_BY_FILTER = gql`
  query getInventoryByFilter(
    $where: data_mapping_treatment_activity_bool_exp!
  ) {
    data_mapping_treatment_activity(where: $where) {
      id
      name_activity
      created_at
      status
      area_modules {
        area {
          description
        }
        id_module
      }
      profile_holders {
        id
        id_combo_data_profile_holders
      }
      location_holders {
        id
        id_combo_data_location_holders
      }
      data_types {
        id
        id_combo_data_data_types
      }
      assessments {
        id
        id_combo_data_risk
      }
      owner {
        displayName
        id
      }
      risk_treatment_activities {
        id_treatment_activity
        risk {
          initial_risk
          risk_monitoring {
            residual_risk
          }
        }
      }
    }
  }
`;
